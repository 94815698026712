@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Nunito:wght@700&display=swap');

.ui.vertical.menu .active.item {
  background: #E4F9FF !important;
  color: #0181BB !important;
}

p {
  font-family: "Karla";
  font-weight: "normal";
  font-size: "18px";
}