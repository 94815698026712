.partnersMenu .active.item {
	background: #e1f8ff !important;
	color: rgb(0, 78, 121) !important;
}

.partnersMenu .item {
	color: #646466 !important;
}

@media only screen and (min-width: 600px) {
	.partnersMenu .item {
		width: 133px !important;
	}
}

.sponsoredTag {
	padding-left: 8px;
	padding-top: 2px;
	border-top-left-radius: 5px;
	position: absolute;
	z-index: 2;
	height: 27px;
	width: 88px;
	background-color: #0275b3;
	color: white;
	font-family: Karla;
	font-size: 14px;
}

.sponsoredTagMobile {
	padding-left: 8px;
	padding-top: 2px;
	border-top-left-radius: 5px;
	position: absolute;
	z-index: 2;
	height: 24px;
	width: 74px;
	background-color: #0275b3;
	color: white;
	font-family: Karla;
	font-size: 12px;
}
