#submitButton:hover {
	color: #ffffff !important;
	background-color: #0B7CB1 !important;
}

#submitButton:focus {
	color: #ffffff !important;
	background-color: #0B3554 !important;
}

.linkHover:hover {
	color: #0B7CB1 !important;
}

.linkHover:focus {
	color: #0B3554 !important;
}