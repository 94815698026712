@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Nunito:wght@700&display=swap');

.grid-sm {
  display: grid;
  grid-gap: 2px;
  grid-template-areas:
    "icon"
    "text";
  justify-items: center;
}

icon-element {
  grid-area: icon;
}

text-element {
  grid-area: text;
}

@media (min-width: 640px) {
  .grid-sm {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "icon text";
  }
}

.grid-sm > * {
  /* border: 1px dotted #212941; */
  /* border-radius: 5px; */
  /* padding: 8px 15px; */
  color: #575757;
  font-size: 16px;
}

#reportBtn:hover {

  background: #4298BB !important;
}