@media (min-width: 768px) and (max-width: 1024px) {
	.nav-bar {
		font-size: 14px !important;
	}
}

.mobile-menu-content {
	z-index: 4;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background: #ffffff;
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	transition: transform 0.3s ease-in-out;
}

.mobile-menu-content a {
	font-size: 1rem;
	padding: 1rem;
	color: #0d0c1d;
	text-decoration: none;
	transition: color 0.3s linear;
	border-bottom: 1px solid #ebebeb;
}

.mobile-menu-content a:hover {
	color: #343078;
}
