:root {
  --post-item-height: 160px;
}

@media only screen and (max-width: 767px) {
  .item > .content {
    padding: 0 !important;
  }

  .scrolling.content .accordion {
    padding: 0 10px;
  }
}

.price {
  color: #128747 !important;
}
.free {
  color: #db2828 !important;
}

.filter-checkbox {
  margin-top: 1rem !important;
}

.filter-according-section {
  padding: 0px !important;
}

.filter-according-title {
  margin-top: 40px !important;
  padding: 0px !important;
  font-size: 1.05rem !important;
}

.post-list-item-content {
  position: relative !important;
  min-height: var(--post-item-height) !important;
  width: 100% !important;
}

.post-item-image {
  height: var(--post-item-height) !important;
}

.listings-container {
  background-color: white;
  padding-top: 56px;
  position: relative;
  top: -48px;
  color: black;
}

.carousel {
  overflow-x: auto;
  display: grid;
  gap: 10px 36px;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, calc(100% -200px)) 100%;
  grid-auto-flow: column;
  scroll-snap-type: x mandatory;
  margin: 0px 16px;
}

.carouselComputer {
  overflow-x: scroll;
  display: grid;
  gap: 10px 36px;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: repeat(3, calc(100% -200px)) 100%;
  grid-auto-flow: column;
  scroll-snap-type: x mandatory;
  margin: 0px 16px;
  scroll-behavior: smooth;
}

.category {
  scroll-snap-align: start;
  padding: 10px;
  padding-left: 0px !important;
  border-radius: 5px !important;
  border: 1px solid lightgray !important;
  width: 208px;
  height: 60px;
  font-size: 16px;
  font-weight: bold;
  color: black !important;
  background-color: white !important;
  cursor: pointer;
  margin: 20px;
  display: flex !important;
  align-items: center;
}

.category:hover {
  border: 3px solid #1a659f !important;
  border-left: 3px solid #1a659f !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.category:focus {
  background-color: #0b3554 !important;
  color: white !important;
}

.categoryName {
  justify-content: center;
  flex: 50%;
}

.categoryPic {
  width: 62px;
  height: 59px;
  border-radius: 3px;
  position: relative;
}
.category:hover .categoryPic {
  height: 56px;
  width: 62px;
}

.category:active .categoryPic {
  height: 56px;
  width: 62px;
  left: 0px;
}

@media (min-width: 768px) {
  .carousel {
    gap: 10px 36px;
    margin: 0px 25px;
  }
  .category {
    width: 250px;
    height: 100px;
  }
  .categoryName {
    font-size: 17px;
  }
  .categoryPic {
    width: 99px;
    height: 94px;
    border-radius: 3px;
    position: relative;
    left: -3px;
  }
  .category:hover .categoryPic {
    height: 94px;
    width: 94px;
    left: 0px;
  }
}

@media (min-width: 992px) {
  .carousel {
    gap: 10px 36px;
    margin: 0px 110px;
  }

  .categoryPic {
    width: 100px;
    height: 99px;
    border-radius: 5px;
    position: relative;
    left: -1px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.carousel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.carousel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.carouselComputer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.carouselComputer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*Filter component CSS*/

.ui.form .field > label {
  font-weight: 500 !important;
  vertical-align: top;
}
.ui.form input[type='checkbox'],
.ui.form textarea {
  vertical-align: middle !important;
}

/* antd Swtich*/
.ant-switch-checked {
  background-color: #1a659f;
}

.distanceInput {
  float: right;
  top: 14px;
  border: 3px solid #1a659f !important;
  border-radius: 5px;
}

.distanceInput > input {
  width: 38px !important;
  color: #1a659f !important;
  border: none !important;
  padding: 5px !important;
  font-weight: bold !important;
}

.distanceInput > .label {
  color: #1a659f !important;
  border: none !important;
  padding: 5px !important;
}

.disabledInput {
  float: right;
  top: 14px;
  border: 3px solid grey !important;
  border-radius: 5px;
}

.disabledInput > input {
  width: 38px !important;
  color: black !important;
  border: none !important;
  padding: 5px !important;
  font-weight: bold !important;
}

.disabledInput > .label {
  color: black !important;
  border: none !important;
  padding: 5px !important;
}
