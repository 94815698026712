@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Nunito:wght@700&display=swap');


p {
  font-family: 'Karla';
}

.howWorksMenu .item {
  width: 133px !important;
}

.howWorksMenu .active.item {
  background: #E1F8FF !important;
  color: #0275B3 !important;
}


.ui.item.menu {
  width: max-content !important;
}

.resourcesCategory {
  opacity: 0.3 !important;
  transition: opacity 1s ease-in-out !important;
  -moz-transition: opacity 1s ease-in-out !important;
  -webkit-transition: opacity 1s ease-in-out !important;
}
.resourcesCategory:hover {
  opacity: 0 !important;
  transition: opacity .45s ease-in-out !important;
  -moz-transition: opacity .45s ease-in-out !important;
  -webkit-transition: opacity .45s ease-in-out !important;
}​

a {
  font-family: 'Karla';
}

.aTagIgnore {
  color: white !important;
}

.connectWithUsBtn:hover {
  background: #0280BB!important;
  color: white!important;
}

.sponsoredTag {
  padding-left: 8px;
  padding-top: 2px;
  border-top-left-radius: 5px;
  position: absolute;
  z-index: 2;
  height: 27px;
  width: 88px;
  background-color: #0275B3;
  color: white;
  font-family: Karla;
  font-size: 14px;
}