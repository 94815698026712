/* General styling ---- starts */

:root {
  /* Carousel padding */
  --slide-padding: 30px;
}

@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Nunito:wght@700&display=swap');


.teamCategory:hover {

  opacity: 0.75 !important;

}
/* Carousel styling */

.slick-dots li button::before {
  color: #C4C4C4;
}

.slick-active li button::before {
  color: #C4C4C4;
}

.slick-dots > li > button:before {
  color: #00ADD8 !important;
}

.slick-next {
  right: -48px !important;
}

.slick-next:before {
  font-size: 40px;
  color: #00ADD8 !important;
}

.slick-prev {
  left: -74px !important;
}

.slick-prev:before {
  font-size: 40px;
  color: #00ADD8 !important;
}

.slick-dots li button:before {
  font-size: 15px;
  line-height: 15px;
}

/*shift the slide dots 30 px to the left of slide to center horizontally*/
.slick-dots {
  margin-left: calc(-1 * var(--slide-padding));
}

.slick-slider {
  font-size: 17px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.box-centering {
  margin-left: -80px;
  height: 100% !important;
}

.carousel-box {
  width: 530px;
  height: 375px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
}

.picture-box {
  min-height: 250px;
  background-color: #f7f7f7;
}

/*push slide dots 45 px down*/
.picture-box .slick-dots {
  bottom: -45px;
}

/* align the left of dots with the left of the Carousel because we define the dots' width is 100% of the carousel width and the dots' width is the same as the carosel's width now.*/
.slick-dots {
  margin-left: calc(-1 * var(--slide-padding));
}

.carousel-text {
  margin-top: 3%;
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  color: #454545;
}

.carousel-img {
  height: 100%; 
  width: 100%;
  object-fit: contain;
}

#quote-right {
  padding: 15px;
  margin-top: 20px;
  position: relative;
  left: 468px;
}


@media (max-width:900px) {
  .carousel-box {
    width: 391px;
  }

  /* align the left of dots with the left of the Carousel because we define the dots' width is 100% of the carousel width and the dots' width is the same as the carosel's width now.*/
  .slick-dots {
  margin-left: calc(-1 * var(--slide-padding));
  }

  .volunteer-box-height {
    height: 460px !important;
  }  

  .carousel-text {
    margin-top: 1%;
    font-size: 16px;
    line-height: 20px;
  }

  #quote-right {
    left: 326px;
    top: -47px;
  }
}

@media (max-width:1000px) {
  .box-centering {
    margin-left: -102px;
  }
}

@media (max-width:950px) {

  .carousel-box {
    height: 420px
  }




  .slick-arrow {
    display: none !important;
  }

}

@media (max-width:820px) {
  .box-centering {
    margin-left: -80px;
  }
}

@media (max-width:700px) {
  .box-centering {
    margin-left: -39px;
  }
}

@media (max-width:562px) {
  .box-centering {
    margin-left: -35px;
  }

  .carousel-box {
    width: 300px;
    height: 420px;
  }

  .volunteer-text {
    font-size: 0.8em;
  }

  .carousel-text {
    margin-top: 1%;
    font-size: 16px;
    line-height: 20px;
  }

  #quote-right {
    left: 242px;
    top: -27px;
  }

}

@media (max-width:376px) {
  .box-centering {
    margin-left: -55px;
  }


}

@media (max-width:321px) {
  .carousel-box {
      width: 241px;
      height: 420px;
  }

  #quote-right {
    left: 181px;
  }

  .volunteer-box-height {
    height: inherit !important;
  }

}

@media (min-width:1200px) {
  .box-centering {
    margin-left: -66px;
  }

}