@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Nunito:wght@700&display=swap');

.subtext {
  font-family: 'Karla',Arial,Helvetica,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #575757;
}

.subtext-underlined {
  font-family: 'Karla',Arial,Helvetica,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #575757;
  text-decoration: underline;
}

a.subtext:hover {
  color: #575757;
}

a.subtext-underlined:hover {
  color: #575757;
  text-decoration: underline;
}

.stickyEnduce {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}