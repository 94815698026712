@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Nunito:wght@700&display=swap');

.toolTip:hover {
	opacity: 0.3;
}

.ui.active.step,
.ui.steps .step.active {
	cursor: auto;
	background-color: #e4f9ff !important; /** old color is #555;*/
}

.ui.active.steps:after,
.ui.steps .step.active:after {
	background-color: #e4f9ff !important; /** old color is #555;*/
}

.ui.steps a.active.step:hover::after {
	background-color: #e4f9ff !important;
}

.ui.steps .step.active:after {
	background-color: #e4f9ff !important;
}

.ui.steps .active.step:after {
	background-color: #e4f9ff !important;
}

.nextStepBtn:hover {
	background-color: #1c73ad !important;
}

.saveDraftBtn:hover {
	background-color: #1c73ad !important;
	color: #ffffff !important;
}

.deleteBtn:hover {
	background-color: #da5c5d !important;
	color: #ffffff !important;
}

.cancelBtn:hover {
	background-color: #808083 !important;
	color: #ffffff !important;
}

.modalHeader {
	font-family: Karla, Arial, Sans-serif;
	font-size: 18px;
	line-height: 21px;
	font-weight: bold;
	color: #1b809b;
}

.simpleKarlaText {
	font-family: Karla, Arial, Sans-serif;
	font-size: 17px;
	line-height: 21px;
}

.equipmentCategoryDescriptions {
	font-family: Karla, Arial, Sans-serif;
	font-size: 16px;
	line-height: 19px;
	color: #646466;
}
