/* Small devices (phones, 767px and down) */
@media only screen and (max-width: 767px) {
  .Div__Container__Background {
    width: 100% !important;
    overflow: hidden;
  }

  .Div__ActionCovid19 {
    width: 200px !important;
    height: auto !important;
    margin-top: 0px !important;
  }

  .Grid__Row__Mobile {
    padding: 0px !important;
    margin: 40px 0px 0px 0px;
  }

  .Covid19__Button__Primary {
    margin-right: 0px !important;
  }

  .Covid19__Button__Secondary {
    margin-right: 0px !important;
  }

  .Covid19__Button__Primary--Large {
    background: #0280bb !important;
    color: #ffffff !important;
    border: 0.5px solid #0280bb !important;
    width: 345px;
    height: 40px;
    margin-right: 0px !important;
  }

  .Covid19__Button__Secondary--Large {
    background: white !important;
    color: #0280bb !important;
    border: 0.5px solid #0280bb !important;
    width: 345px;
    height: 40px;
    margin-top: 16px !important;
    margin-right: 0px !important;
  }
}

/* Medium devices (tablets, 768px and up to 1199) */
@media only screen and (min-width: 768px) {
  .Div__Container__Background {
    width: 100% !important;
    overflow: hidden;
  }

  .Covid19__Button__Primary {
    margin-right: 0px !important;
  }

  .Covid19__Button__Secondary {
    margin-right: 0px !important;
  }

  .Grid__Row__Tablet {
    margin: 0;
    padding: 40px 0px 0px 0px;
  }
}

/* Large devices (laptops/desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .Div__Container__Background {
    margin: 0 auto;
    justify-content: center;
  }
}

.Div__Container__Covid19SafetyExchange {
  margin: 0 auto;
  max-width: 1440px;
}

.Div__Container__Remember {
  width: 100%;
  background: #f2f8fb;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

.Div__Container__GetInvolved {
  margin-top: 96px;
  margin-bottom: 80px;
  text-align: center;
}

.Div__Container__Background {
  position: relative;
  overflow: hidden;
  width: 1440px;
  height: 713px;
}

.Div__ActionCovid19 {
  width: 200px;
  height: auto;
  text-align: center;
}

.Div__Image {
  width: 100%;
}

.Div__Padding--Top--24px {
  padding-top: 24px;
}

.Div__Margin--Top--80px {
  margin-top: 80px;
}

.Div__Margin--Bot--60px {
  margin-bottom: 60px;
}

.Div__Margin--Bot--80px--Centered {
  margin-bottom: 80px;
  text-align: center;
}

.Div__Margin--Top--Bot--80px--Centered {
  margin-top: 20px;
  margin-bottom: 80px;
  text-align: center;
}

.Div__Margin--Top--80px--Bot--96px {
  margin-top: 80px;
  margin-bottom: 96px;
}

.Covid19__H1 {
  font-family: "Nunito", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #0280bb;
  line-height: 44px;
}

.Covid19__H3 {
  font-family: "Nunito", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #0280bb;
  line-height: 33px;
}

.Covid19__P {
  font-family: "Karla", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #000000;
  margin: 0 auto;
  line-height: 24px;
  padding-bottom: 24px;
}

.Covid19__P--NoPadding {
  font-family: "Karla", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #000000;
  margin: 0 auto;
  line-height: 24px;
}

.Covid19__Button{
  background: white !important;
  color: #0280bb !important;
  border: 0.5px solid #0280bb !important;
  width: 290px;
  height: 40px;
}

.Covid19__Button:hover {
    background-color: #1C73AD!important;
    color: #FFFFFF !important;
}

.Covid19__Link__WithIcon {
  text-decoration: none;
  color: #575757;
  margin-bottom: 40px;
}
